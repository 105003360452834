import React from 'react';
import { BsTwitter, BsInstagram, BsLinkedin, BsGithub, BsWhatsapp } from 'react-icons/bs';
import { FaFacebookF } from 'react-icons/fa';

const SocialMedia = () => (
  <div className="app__social">
    <div>
    <a href='https://www.linkedin.com/in/mohammed-unais-hagalwadi' target='_blank'>
      <BsLinkedin />
    </a>
    </div>
    <div>
    <a href='https://github.com/Muhammad-Unais'target='_blank'>
      <BsGithub />
    </a>
    </div>
    <div>
    <a href='https://api.whatsapp.com/send/?phone=%2B919901471599&text&type=phone_number app_absent=0'target='_blank'>
      <BsWhatsapp />
    </a>
    </div>
  </div>
);

export default SocialMedia;