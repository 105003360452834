import './Navbar.scss';
import { images } from '../../constants';
import { HiMenuAlt4, HiX } from 'react-icons/hi';
import { color, motion } from 'framer-motion';
import React, { useState } from 'react';
import cv from '../../assets/UnaisResume.pdf';

const Navbar = () => {
  const [toggle, setToggle] = useState(false);

  return (
    <nav className="app__navbar">
      <div className="app__navbar-logo">
        <img className='nav-logo' src={images.unaislogo} alt="logo" />
      </div>
      <ul className="app__navbar-links">
        {['home', 'project', 'skills','education', 'contact'].map((item) => (
          <li className="app__flex p-text headertext" key={`link-${item}`}>
            <div />
            <a href={`#${item}`}>{item}</a>
          </li>
        ))}
      </ul>

      <div className='cv-cont'>
        <a href={images.unais_cv} download="unais-cv" className='cv-btn'>Download my CV</a>
      </div>

      <div className="app__navbar-menu">
        <HiMenuAlt4 onClick={() => setToggle(true)} />
        {toggle && (
          <motion.div
            whileInView={{ x: [300, 0] }}
            transition={{ duration: 0.85, ease: 'easeOut' }}
          >
            <HiX onClick={() => setToggle(false)} />
            <ul>
            {/* about down */}
              {['home', 'project', 'skills','education', 'contact'].map((item) => (
                <li key={item}>
                  <a href={`#${item}`} onClick={() => setToggle(false)}>
                    {item}
                  </a>
                </li>
              ))}
            </ul>
          </motion.div>
        )}
      </div>
    </nav>
  );
};

export default Navbar;